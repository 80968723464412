import React from "react";
import { Box, Center, Heading } from "@chakra-ui/react";

import Social from "./social";
import Community from "./community";

const Splash = () => {
  return (
    <Center>
      <Box zIndex="1" color="white" fontFamily="VT323">
        <Community />
        <Social />
        <Heading size="xl" textAlign="center" fontWeight="normal" mt="8" mb="24" fontFamily="VT323" letterSpacing="0.1rem">
          WEBSITE COMING SOON
        </Heading>
      </Box>
    </Center>
  );
};

export default Splash;
