import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Box, Text, Link, Center } from "@chakra-ui/react";

const Community = () => {
    return (
        <Box mt={["24", "2"]} textAlign="center" mb={["16"]}>
            {/* <Text fontSize={{ base: "4xl", md: "5xl" }} letterSpacing={["0.15rem", "0.3rem"]}>
                JOIN THE COMMUNITY
            </Text> */}
            <Center>
                <Link
                    href="https://discord.gg/deathgirl"
                    colorScheme="red"
                    size="lg"
                    variant="outline"
                    borderRadius="full"
                    letterSpacing="0.3rem"
                    px="12"
                    py="4"
                    border="2px solid var(--chakra-colors-red-600)"
                    w="96"
                    display="block"
                    bg="red"
                    fontWeight="bold"
                    fontSize={{ base: "lg", md: "2xl" }}
                    transition="all 0.6s ease"
                    _hover={{ bg: "black", color: "red", borderColor: "var(--chakra-colors-red)" }}
                >
                    JOIN THE COMMUNITY
                </Link>
            </Center>
        </Box>
    );
};

export default Community;
