import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Intro from "../components/intro";
import Splash from "../components/splash";

import "@fontsource/vt323";

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="DEATH GIRL | NFT" />
            <Intro />
            <Splash />
        </Layout>
    );
};

export default IndexPage;
