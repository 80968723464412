import React from "react";
import { Box, Center } from "@chakra-ui/react";
import drip from "../images/deathgirl-drip.mp4";

const Intro = () => (
  <Center>
    <Box maxWidth="1000px" mt={[0, "-24", "-48"]} mb={["-24", "-24", "-36"]} ml="1">
      <video autoPlay muted playsInline loop>
        <source src={drip} type="video/mp4" />
      </video>
    </Box>
  </Center>
);

export default Intro;
